.App {
  text-align: center;
  font-family: "Inter", sans-serif;
}

.App-logo {
  position: absolute;
  top: 10vh;
  height: 35vh;
  width: 75vw;
  right: 12.5vw;
  pointer-events: none;
  /* align-items: center; */
}
h1 {
  color: #fff;
  font-size: 1.25em;
  margin-top: 0.75em;
  margin-bottom: 0.5em;
}
p {
  color: #fff;
  font-size: 0.5em;
  margin-top: 0.5em;
}
a {
  /* width: 100%; */
  margin-bottom: 20px;
  border-radius: 5px;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.5em;
  padding: 0.5em 1em;
  /* text-shadow: 0 0 1px #fff; */
  text-decoration: none; /* Remove default underline on anchor elements */
  display: inline-block; /* Ensure consistent spacing */
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  color: #fff;
}
a:hover {
  color: black;
  background-color: #fff;
}

.Link-hightlight {
  color: #ceeb00;
  border-color: #ceeb00;
  font-weight: bold;
}

.Link-hightlight:hover {
  color: black;
  background-color: #ceeb00;
  font-weight: bold;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }CEEB00 #1e1e1e
} */

.App-description {
  position: absolute;
  font-size: 1.5em;
  color: #fff;
  background-color: #1e1e1e;
  /* border-radius: 2em; */
  /* margin-top: 2em; */
  top: 50vh;
  height: 50vh;
  width: 100vw;
  left: 0;
  /* padding: 0.5em; */
  bottom: 0;
}

/* For screens smaller than 600px */
@media (max-width: 600px) {
  .App-text {
    width: 90vw;
    margin-left: 5vw;
  }
  .App-social {
    display: grid;
    grid-template-columns: 100%;
    /* width: 50vw; */
    /* column-gap: 5%; */
  }
}

/* For screens larger than 600px */
@media (min-width: 600px) {
  .App-text {
    width: 50vw;
    margin-left: 25vw;
    /* right: 25vw; */
  }
  .App-social {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 50vw;
    column-gap: 5%;
  }
}

.App-header {
  position: absolute;
  background-color: #ceeb00;
  /* min-height: 100vh; */
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
